/*@supports (-webkit-appearance:none){
  header.masterhead {
    //background-image: linear-gradient(0deg, rgba(255, 106, 0, .5), rgba(238, 9, 121, .3)), url("/assets/images/hero.webp");
    background-image: url("/assets/images/hero.webp");
  }
}*/

header.masthead {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding:{
    //top: calc(7rem + 70px);
    top: 7rem;
    bottom: 7rem;
  }
  background: {
    //image: linear-gradient(0deg, rgba(255, 106, 0, .5), rgba(238, 9, 121, .3)), url("/assets/images/hero.jpg");
    //image: linear-gradient(0deg, rgba($dark-black, .5), rgba($dark-black, .3)), url("/assets/images/hero.jpg");
    image: url("/assets/images/hero.jpg");
    image: url("/assets/images/hero.webp");
    repeat: no-repeat;
    position: center;
    size: cover;
    //attachment: scroll;
    attachment: fixed;
  }

  h1, h2 {
    line-height: 1;
    //letter-spacing: 0.15em;
    margin: 0.875em auto;
    font-weight: 300;
    letter-spacing: 0;
    //color: $grey-dark;
    color: $dark-black;
    //text-shadow: 1px 10px 17px #fff;
    //text-shadow: 1px 1px 2px #fff;
  }

  .masthead-content {
    z-index: 1;
    position: relative;

    .content-wrapper {
      padding: 3rem 0;
      background: rgba(255,255,255, 0.65);
    }

    .masthead-heading {
      font-size: 4rem !important;
      text-transform: uppercase;

      @media (max-width: 577px) {
        font-size: 2.5rem !important;
      }
    }
    .masthead-subheading {
      font-size: 2rem !important;
    }
  }
  /*.bg-circle {
    z-index: 0;
    position: absolute;
    border-radius: 100%;
    //background: linear-gradient(0deg, rgba(255, 106, 0, .2), rgba(238, 9, 121, .1));
    background: linear-gradient(0deg, rgba($dark-black, .2), rgba($dark-black, .1));
  }
  .bg-circle-1 {
    height: 90rem;
    width: 90rem;
    bottom: -55rem;
    left: -55rem
  }
  .bg-circle-2 {
    height: 50rem;
    width: 50rem;
    top: -25rem;
    right: -25rem
  }
  .bg-circle-3 {
    height: 20rem;
    width: 20rem;
    bottom: -10rem;
    right: 5%
  }
  .bg-circle-4 {
    height: 30rem;
    width: 30rem;
    top: -5rem;
    right: 35%
  }*/
}


@media (min-width: 992px) {
  header.masthead {
    //padding-top: calc(10rem + 55px);
    padding-top: 10rem;
    padding-bottom: 10rem
  }
  header.masthead .masthead-content .masthead-heading {
    font-size: 6rem
  }
  header.masthead .masthead-content .masthead-subheading {
    font-size: 4rem
  }
}


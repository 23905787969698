//@import url('https://fonts.googleapis.com/css?family=Catamaran:400,600,800|Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato|Nunito+Sans&display=swap');

body{
  font-family:'Lato', 'Helvetica Neue',Arial,'Noto Sans', sans-serif !important;
  color: $grey !important;
}
h1,h2,h3,h4,h5,h6{
  //font-family:Catamaran,sans-serif !important;
  font-family:'Nunito Sans',sans-serif !important;
  //font-weight:800!important;
}

h2.display-4 {
  line-height: 1;
  //margin-bottom: 1.6rem;
  letter-spacing: 0.15em;
  margin: 0.875em auto;
  font-size:2.4rem;
}
footer h4 {
  @extend h2.display-4;
  font-size: 1.2rem;
}

.price {
  font: {
    size: 2.4rem;
    weight: 800;
  }
}

.thick-border {
  border-bottom:5px solid $grey;
  padding-bottom: 10px;
}

#quote-result .bg-warning {
  color: #000 !important;
}

.premium, .text-primary {
  color: $primary !important;
}

.highlight-clean {
  //color: rgba(255,255,255, 0.8); // $white
  color: $white; // $white
  background-color: $primary;
  /*padding: 50px 0;*/

  a {
    color: rgba(255,255,255, 0.8);
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  .btn-primary {
    background-color: $white !important;
    border-color: $white !important;
    color: $grey;
    &:hover {
      background-color: $yellow !important;
      border-color: $yellow !important;
      color: $grey;
    }
  }
}

/*.highlight-clean p {
  color: #7d8285;
}*/

/*
.highlight-clean h2 {
  font-weight: bold;
  margin-bottom: 25px;
  //line-height: 1.5;
  padding-top: 0;
  margin-top: 0;
  color: $white;
}
*/


.highlight-clean .intro {
  //font-size: 16px;
  max-width: 500px;
  //margin: 0 auto 25px;
}

.highlight-clean .buttons {
  text-align: center;
}

/*.highlight-clean .buttons .btn {
  padding: 16px 32px;
  margin: 6px;
  border: none;
  background: none;
  box-shadow: none;
  text-shadow: none;
  opacity: 0.9;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 1;
  outline: none;
  background-color: #ddd;
}*/

/*.highlight-clean .buttons .btn:hover {
  opacity: 1;
}

.highlight-clean .buttons .btn:active {
  transform: translateY(1px);
}

.highlight-clean .buttons .btn-primary {
  background-color: #055ada;
  color: #fff;
}*/


.testimonials-clean {
  color: inherit;//$grey-dark;
  //background-color: $bg-grey-lighter;
  background : {
    color: transparent !important;
    image: url("/assets/images/bg-testimonials.jpg");
    image: url("/assets/images/bg-testimonials.webp");
    repeat: no-repeat;
    size: cover;
    position: left bottom;
    attachment: fixed;
  }

  /*h2 {
    //font-weight: bold;
    //margin-bottom: 40px;
    //padding-top: 40px;
    //color: inherit;
    @media (max-width: 767px) {
      margin-bottom: 25px;
      padding-top: 25px;
      font-size: 24px;
    }
  }*/

  h2 {
    color: $primary;
  }

  .intro {
    max-width: 500px;
    margin: 0 auto;

    p {
      margin-bottom: 0;
      color: $white; //$grey-light;
    }
  }
  p {
    color: $txt-light-grey;
  }
  /*h2+p {
    color: $white; //$grey-light;
  }*/

  .people {
    padding: 50px 0 20px;
  }

  .item {
    margin-bottom: 32px;
    /*@media (min-width: 768px) {
      height: 220px;
    }*/

    .box { // bubble
      padding: 30px;
      //background-color: $white;
      background-color: $white;
      position: relative;
      &:after { //
        content: '';
        position: absolute;
        left: 30px;
        bottom: -24px;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        border-width: 12px 15px;
        border-top-color: $white;
      }
    }

    .author {
      //margin-top: 28px;
      margin-top: 20px;
      padding-left: 25px;

      i {
        color: $white;
        /*max-width: 40px;
        max-height: 40px;*/
        //float: left;
        margin: {
          right: 12px;
          top: -5px;
        }
        //color: darken($bg-grey-lighter, 24%);
        padding: 5px 6.5px;
        /*border: {
          radius: 50%;
          width: 2px;
          style: solid;
          color: darken($bg-grey-lighter, 24%);
        }*/
      }
    }

    .name {
      font-weight: bold;
      margin: {
        bottom: 2px;
        left: 10px;
      }
      color: $primary; //$grey;
    }

    /*.title {
      font-size: 13px;
      color: darken($bg-grey-lighter, 24%);
    }*/

    .description {
      /*font-size: 15px;*/
      margin-bottom: 0;
    }

    /*img {
      max-width: 40px;
      float: left;
      margin-right: 12px;
      margin-top: -5px;
    }*/

  }
}

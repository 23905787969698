/*
green: #78c148
red: #d94f61
mask: #94dbda
*/
.gdpr_btn{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  margin:0;
  padding:8px 16px;
  border:0;
  font-size:14px;
  color:#fff;
  cursor:pointer;
  text-decoration:none;
  text-align:center
}
.gdpr_btn-round{
  border-radius:5px
}
.gdpr_btn-success{
  background:#78c148
}
.gdpr_btn-error{
  background:#d94f61
}
.gdpr_btn-default{
  background:#d6d6d6;
  color:#111
}
.gdpr_btn:hover{
  opacity:.8
}
@-webkit-keyframes slide{
  0%{
    bottom:-100%
  }
  to{
    bottom:0
  }
}
@keyframes slide{
  0%{
    bottom:-100%
  }
  to{
    bottom:0
  }
}
.gdpr_banner{
  position:fixed;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  right:0;
  bottom:0;
  left:0;
  max-width:100vw;
  padding:0;
  background:#454d5d;
  font-size:16px;
  color:#fff;
  text-align:center;
  z-index:2147483645;
  -webkit-animation:slide .5s;
  animation:slide .5s
}
.gdpr_banner,.gdpr_banner-content{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:stretch;
  -ms-flex-align:stretch;
  align-items:stretch;
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
  -ms-flex-direction:row;
  flex-direction:row
}
.gdpr_banner-content{
  -webkit-box-pack:start;
  -ms-flex-pack:start;
  justify-content:flex-start;
  max-width:990px;
  width:100%
}
@media screen and (max-width:768px){
  .gdpr_banner-content{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column
  }
}
.gdpr_banner-text{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  padding:16px;
  text-align:left
}
@media screen and (max-width:768px){
  .gdpr_banner-text{
    -webkit-box-flex:0;
    -ms-flex:none;
    flex:none;
    max-width:100%;
    text-align:center
  }
}
.gdpr_banner-actions{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  min-height:50px
}
@media screen and (max-width:768px){
  .gdpr_banner-actions{
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1
  }
}
.gdpr_banner-actions button{
  white-space:nowrap;
  font-size:16px
}
@media screen and (max-width:768px){
  .gdpr_banner-actions button{
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    min-height:60px
  }
}
.gdpr_banner-actions button:after,.gdpr_banner-actions button:before{
  content:"";
  -webkit-box-flex:1;
  -ms-flex:1 0 auto;
  flex:1 0 auto
}
.gdpr_switch{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  position:relative;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  height:32px;
  margin:0 8px
}
.gdpr_children{
  text-align:center;
  min-width:110px;
  font-size:14px;
  color:#666
}
.gdpr_children.switch_activated{
  color:#78c148
}
.gdpr_switch>input{
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  display:block;
  z-index:-1
}
.gdpr_shadow,.gdpr_switch>input{
  position:absolute;
  margin:0;
  outline:none;
  opacity:0;
  pointer-events:none
}
.gdpr_shadow{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  top:-6px;
  left:-12px;
  width:44px;
  height:44px;
  border-radius:50%;
  background-color:rgba(0,0,0,.3);
  -webkit-transform:scale(1);
  transform:scale(1);
  -webkit-transition:opacity .3s .1s,-webkit-transform .3s .1s;
  transition:opacity .3s .1s,-webkit-transform .3s .1s;
  transition:opacity .3s .1s,transform .3s .1s;
  transition:opacity .3s .1s,transform .3s .1s,-webkit-transform .3s .1s
}
.gdpr_switch>input:checked+span>.gdpr_shadow{
  -webkit-transform:translateX(25px);
  transform:translateX(25px);
  background-color:rgba(0,0,0,.3)
}
.gdpr_switch>input:active+span>.gdpr_shadow{
  opacity:1;
  -webkit-transform:scale(0);
  transform:scale(0);
  -webkit-transition:opacity 0s,-webkit-transform 0s;
  transition:opacity 0s,-webkit-transform 0s;
  transition:transform 0s,opacity 0s;
  transition:transform 0s,opacity 0s,-webkit-transform 0s
}
.gdpr_switch:hover>input+span>.gdpr_shadow{
  opacity:.04
}
.gdpr_switch>input:focus+span>.gdpr_shadow{
  opacity:.12
}
.gdpr_switch:hover>input:focus+span>.gdpr_shadow{
  opacity:.16
}
.gdpr_switch>span.gdpr_switch{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  position:relative;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  width:45px;
  margin:0 8px 0 0;
  padding:0;
  cursor:pointer
}
.gdpr_switch>span.gdpr_switch:before{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  width:100%;
  height:14px;
  border-radius:7px;
  background-color:#e3e3e3;
  vertical-align:top
}
.gdpr_switch>span.gdpr_switch:after,.gdpr_switch>span.gdpr_switch:before{
  content:"";
  margin:0;
  padding:0;
  -webkit-transition:all .2s;
  transition:all .2s
}
.gdpr_switch>span.gdpr_switch:after{
  position:absolute;
  top:6px;
  left:0;
  border-radius:50%;
  width:20px;
  height:20px;
  background-color:#fff;
  -webkit-box-shadow:0 0 5px 1px #454d5d;
  box-shadow:0 0 5px 1px #454d5d
}
.gdpr_switch>input:checked+span.gdpr_switch:before{
  background-color:#78c148
}
.gdpr_switch>input:checked+span.gdpr_switch:after{
  background-color:#fff;
  -webkit-box-shadow:0 0 5px 1px #78c148;
  box-shadow:0 0 5px 1px #78c148;
  -webkit-transform:translateX(25px);
  transform:translateX(25px)
}
.gdpr_list_element{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  margin:0;
  padding:8px;
  min-height:32px;
  background:#fff;
  border-top:1px solid #e3e3e3
}
.gdpr_list_element:after,.gdpr_list_element:before{
  content:"";
  -webkit-box-flex:0;
  -ms-flex:0 0 10px;
  flex:0 0 10px
}
.gdpr_list_element-activate{
  border-left:7px solid #78c148
}
.gdpr_list_element-disabled{
  border-left:7px solid #d94f61
}
.gdpr_list_element-title{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center
}
.gdpr_list_element-title strong{
  -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  padding:8px;
  font-weight:700
}
.gdpr_list_element-desc{
  padding:8px;
  font-size:14px;
  color:#666
}
.gdpr_list_element .gdpr_element-action{
  padding:8px
}
.gdpr_element-action{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center
}
.gdpr_element-action .gdpr_btn{
  margin:8px
}
@-webkit-keyframes show{
  0%{
    opacity:0
  }
  to{
    opacity:1
  }
}
@keyframes show{
  0%{
    opacity:0
  }
  to{
    opacity:1
  }
}
.gdpr_modal{
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  -webkit-animation:show .4s;
  animation:show .4s;
  background:rgba(0,0,0,.1);
  z-index:2147483645
}
.gdpr_modal,.gdpr_modal_content{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
}
.gdpr_modal_content{
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  width:80%;
  max-height:85vh;
  background:#fefefe;
  -webkit-box-shadow:0 0 15px #454d5d;
  box-shadow:0 0 15px #454d5d
}
@media screen and (max-width:768px){
  .gdpr_modal_content{
    width:85%
  }
}
@media screen and (max-width:576px){
  .gdpr_modal_content{
    width:95%
  }
}
.gdpr_modal_button-all{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:end;
  -ms-flex-pack:end;
  justify-content:flex-end;
  border-top:1px solid #f0f0f0;
  border-bottom:1px solid #f0f0f0
}
.gdpr_modal_button-all .gdpr_element-action{
  padding:5px 0
}
.gdpr_modal_button-all .gdpr_btn{
  font-size:12px
}
.gdpr_modal_head,.gdpr_modal_head-content{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column
}
.gdpr_modal_head-content{
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  padding:5px 0
}
.gdpr_modal_head-content:after,.gdpr_modal_head-content:before{
  content:"";
  -webkit-box-flex:0;
  -ms-flex:0 0 10px;
  flex:0 0 10px
}
.gdpr_modal_head header{
  -ms-flex-align:center;
  height:30px;
  min-height:30px;
  padding:16px 8px;
  background:none;
  font-size:20px
}
.gdpr_modal_head header,.gdpr_modal_head header strong{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  align-items:center
}
.gdpr_modal_head header strong{
  -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  -ms-flex-align:center;
  padding:0 8px
}
.gdpr_modal_head header .gdpr_btn{
  height:40px;
  padding:8px 5px;
  justify-self:flex-end;
  background:none
}
.gdpr_modal_list-head{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-wrap:wrap;
  flex-wrap:wrap;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  min-height:32px;
  padding:5px 8px;
  background:#f9f9f9;
  font-size:20px;
  font-weight:700;
  color:#666;
  text-transform:capitalize;
  border-top:1px solid #e3e3e3
}
.gdpr_modal_list-head:first-child{
  border-top:none
}
.gdpr_modal_list-head svg{
  fill:#666
}
.gdpr_modal_list-head-text{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-flex:1;
  -ms-flex:1;
  flex:1
}
.gdpr_modal_list-content{
  max-width:100%;
  max-height:calc(85vh - 190px);
  overflow-y:scroll;
  -ms-overflow-style:-ms-autohiding-scrollbar;
  background:#fff
}
.gdpr_modal_action{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  position:relative;
  -webkit-box-pack:start;
  -ms-flex-pack:start;
  justify-content:flex-start;
  -webkit-box-align:end;
  -ms-flex-align:end;
  align-items:flex-end;
  min-height:60px;
  border-top:1px solid #f0f0f0;
  background:#fff
}
.gdpr_modal_action:after,.gdpr_modal_action:before{
  content:"";
  -webkit-box-flex:0;
  -ms-flex:0 0 10px;
  flex:0 0 10px
}
.gdpr_modal_action button{
  -webkit-box-flex:1;
  -ms-flex:1;
  flex:1;
  border-radius:5px;
  margin-right:8px;
  font-size:18px
}
.gdpr-mask{
  position:relative
}
.gdpr_mask-content{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -ms-flex-direction:column;
  flex-direction:column;
  background:#f7f7f7
}
.gdpr_mask-desc{
  margin:16px 0
}
@media screen and (max-width:576px){
  .gdpr_mask-action{
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
  }
}
.gdpr_mask-action .gdpr_btn{
  background:#94dbda;
  color:#333
}

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;

  &:active, &:focus, &:hover {
    background-color: darken(saturate($primary, 0.14), 10.00) !important;
    border-color: darken(saturate($primary, 0.14), 10.00) !important;
  }

  &:focus {
    //box-shadow: 0 0 0 .2rem rgba(238, 9, 121, .5);
    box-shadow: 0 0 0 .2rem rgba($primary, .5);
  }
}



.btn-xl {
  text-transform: uppercase;
  padding: 1.5rem 3rem !important;
  font-size: .9rem !important;
  font-weight: 600 !important;
  letter-spacing: .1rem
}

form {
  label {
    font-weight: 700;
  }
  .form-control {
    height: calc(1.6em + 0.75rem + 10px);
    //background: #eeeeee;
    border: {
      top:  none;
      left: none;
      right: none;
      bottom: 1px solid #dfe7f1;
      radius: 0;
    }
    box-shadow: none !important;
    outline: none !important;
    color: inherit;
    //text-indent: 6px;
    //font-size: 1.4rem;
    //height: 42px;
    /*&:focus {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }*/
  }

  textarea.form-control {
    height: inherit !important;
  }

  .input-group-text {

    background: none;
    border: {
      top:none;
      left: none;
      right: none;
      bottom: 1px solid #dfe7f1;
      radius: 0;
    }
    i {
      color: $grey !important;
    }
  }

  .btn {
    border: none;
    border-radius: 4px;
    padding: 11px 24px;
    box-shadow: none;
    margin-top: 35px;
    text-shadow: none;
    outline: none !important;
    /*&:active {
      transform: translateY(1px); // if this is on, whenever the form is clicked, it moves down and back up.
    }*/
  }

  /*.btn-primary {
      background: $pink-dark; //#f4476b;
  !*border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 35px;
  text-shadow: none;
  outline: none !important;*!*/

  /*&:hover, &:active {
                background: $pink-darker; //#eb3b60;
            }*/
}

.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  //set dimensions for invisible container
  .input-container {
    position: relative;
    height:  5rem;
    width:  7rem;
    margin: 0.5rem;

    //make actual radio input invisible
    // + stretch to fill container
    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    //default tile styles
    .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 2px solid $primary;
      border-radius: 5px;
      padding: 1rem;
      transition: transform 300ms ease;
      .icon {
        margin-top: 5px;
      }
    }
    .icon svg {
      fill: $primary;
      width: 3rem;
      height: 3rem;
    }
    .radio-tile-label {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      //color: $primary;
    }

    //active tile styles
    .radio-button:checked + .radio-tile,  .radio-button:hover + .radio-tile{
      background-color: $primary;
      border: 2px solid $primary;
      color: white;
      //transform: scale(1.1, 1.1);

      .icon svg {
        fill: white;
        background-color: $primary;
      }
      .radio-tile-label {
        color: white;
        background-color: $primary;
      }
    }
  }
}

#instructForm {
  .form-group {
    margin: {
      top: 1.5rem;
      bottom: 1.5rem;
    }
  }
}
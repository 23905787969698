.features-boxed {
  color: inherit;
  background-color: $bg-grey-lighter;

  /*i {
    color: $blue !important;
  }*/
/*  h2 {
    color: $primary;
  }*/
  .intro {
    //font-size: 16px;

    max-width: 500px;
    margin: 0 auto;
    p {
      margin-bottom: 0;
      /*color: $white;*/
    }
  }
  .features {
    padding: 50px 0;
  }
  .item {
    text-align: center;
    .box {
      text-align: center;
      padding: 30px;
      background-color: #fff;
      margin-bottom: 30px;
    }
    .icon {
      font-size: 60px;
      color: $primary;
      margin-top: 20px;
      margin-bottom: 35px;
    }
    .name {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 8px;
      margin-top: 0;
      color: inherit;
    }
    /*.description {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 20px;

}*/
  }
}

/*.features-boxed p {
  color: #7d8285;
}*/

/*.features-boxed h2 {
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 40px;
  color: inherit;
}*/

/*@media (max-width:767px) {
  .features-boxed h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}*/


#contact {
  background : {
    color: transparent !important;
    image: url("/assets/images/bg-contact.jpg");
    image: url("/assets/images/bg-contact.webp");
    repeat: no-repeat;
    size: cover;
    position: left bottom;
    attachment: fixed;
  }
  h2 {
    color: $primary;
  }
  .intro {
    color: $white;
  }
}

$red: #e41749; // for callout border
$green: #209488;//#69A590; //#15cda8;
$blue: rgb(24, 54, 96);// #183660 //#1eafed;
$yellow: #fdd043;

$primary: #209488;// #b1956e; //#c6ad53; //#937a4b; //#a79344; // #ee0979 //= rgb(238,9,121)
$orange: #ff6a00; //= rgb(255,106,0)


$grey: #60605e; //#3c3b5c; // main text
$txt-light-grey: #303a52; // sub-text
$grey-dark: #313437;
$grey-light: #7d8285;

$bg-light-grey: #eef4f7;
$bg-grey-lighter: #f8f8f8;// #E5E4E4; //#dfe0df; //#f6f6f0;// #eef2f5;//#f2e9e4; //#eef4f7;

$bg-dark: #34314f;
$dark-black: #010101; //#4F4538; //#4d4a45; //#212529; // nav & footer

$white: #ffffff;

.bg-primary {
  background-color: $blue !important;
}
hr {
  //@media (min-width: 992px) {
    border-top: 5px solid $grey;
  //}
}

#back-to-top-button {
    //border-radius: 4px;
    border-radius: 50%;
    display: inline-block;
    //background-color: $orange;
    background-color: $green;
    width: 50px;
    height: 50px;
    font-size: 2em;
    line-height: 50px;
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;

    &:hover {
        cursor: pointer;
        background-color: $primary;
    }
    &:active {
        background-color: $primary;
    }
    &.show {
        opacity: 1;
        visibility: visible;
    }
    @media (min-width: 500px) {
        margin: 30px;
    }
}

// bordered div
.bordered:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 50%; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 1px solid $primary; /* This creates the border. Replace black with whatever color you want. */
}
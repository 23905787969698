.bg-black {
  background-color: $dark-black !important
}

footer {
  color: rgba(255,255,255,.5);

  .col-md-6 {
    border-left: 1px solid $primary;
  }

  h4 {
    color: rgba(255,255,255,.7);
  }

  a {
    color: rgba(255,255,255,.5);
    transition: .1s color ease;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}
/*nav.bg-dark, .bg-dark {
  background-color: $bg-dark !important;
}

.navbar-custom {
  .navbar-brand {
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: .1rem;
    //font-weight: 700
  }
  .navbar-nav .nav-item .nav-link {
    text-transform: uppercase;
    font-size: .8rem;
    //font-weight: 700;
    letter-spacing: .1rem
  }
}*/

//==================
// NEW NAV
// Navigation Variables
$content-width: 1000px;
$breakpoint: 799px;
$nav-height: 70px;
$nav-brand-padding: 5px;
$nav-background: #fff;// $dark-black; //#262626;
$nav-font-color: $primary; //#ffffff;
$link-hover-color: $primary; //#2581DC;

// Outer navigation wrapper
.navigation {
  height: $nav-height;
  background: $nav-background;
}

// Logo and branding
.brand {
  text-align: center;
  line-height: $nav-height;
  text-transform: uppercase;
  a,
  a:visited {
    color: $nav-font-color;
    text-decoration: none;
  }
  img {
    max-height: calc(#{$nav-height} - #{$nav-brand-padding} * 2);
    padding:$nav-brand-padding 0;
  }
}

// Container with no padding for navbar
.nav-container {
  max-width: $content-width;
  margin: 0 auto;
}

// Navigation
/*nav {
  float: right;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      float: left;
      position: relative;
      a,
      a:visited {
        display: block;
        padding: 0 20px;
        line-height: $nav-height;
        background: $nav-background;
        color: $nav-font-color;
        text-decoration: none;
        &:hover {
          //background: $link-hover-color;
          background: $nav-font-color;
          //background: transparent;
          //color: $nav-font-color;
          color: $nav-background;
          text-decoration: none;
        }
        &:not(:only-child):after {
          padding-left: 4px;
          content: ' ▾';
        }
      } // Dropdown list
      ul li {
        min-width: 190px;
        a {
          padding: 15px;
          line-height: 20px;
        }
      }
    }
  }
}*/

// Dropdown list binds to JS toggle event
.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

/* Mobile navigation */

// Binds to JS Toggle
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: $nav-background;
  height: $nav-height;
  width: $nav-height;
}
@media only screen and (max-width: 798px) {
  // Hamburger nav visible on mobile only
  .nav-mobile {
    display: block;
  }
  nav {
    width: 100%;
    padding: $nav-height 0 0;
    ul {
      display: none;
      li {
        float: none;
        a {
          padding: 15px;
          line-height: 20px;
        }
        ul li a {
          padding-left: 30px;
        }
      }
    }
  }
  .nav-dropdown {
    position: static;
  }
}
@media screen and (min-width: $breakpoint) {
  .nav-list {
    display: block !important;
  }
}
#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0;
  span,
  span:before,
  span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: $nav-font-color;
    position: absolute;
    display: block;
    content: '';
    transition: all 300ms ease-in-out;
  }
  span:before {
    top: -10px;
  }
  span:after {
    bottom: -10px;
  }
  &.active span {
    background-color: transparent;
    &:before,
    &:after {
      top: 0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

/*
// Page content
article {
  max-width: $content-width;
  margin: 0 auto;
  padding: 10px;
}*/

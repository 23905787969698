// to make not print, use Bootstrap's .d-print-none

@media print {
  body {
    color: $dark-black !important;
  }
  .brand {
    img {
      max-height: calc(#{$nav-height} - #{$nav-brand-padding} * 2);
      padding:$nav-brand-padding 0;
    }
  }

  table {
    break-inside: avoid;
  }
}

@page {
  margin: 75pt;
}
